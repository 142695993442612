import type { ComponentPropsWithoutRef } from 'react'

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { clsx } from 'clsx'

import { t } from 'utils/i18n/translate'

export type NotificationElementProps = {
  layout: 'error' | 'info' | 'success' | 'warning'
  onClose?: () => void
} & ComponentPropsWithoutRef<'div'>

const classes = {
  base: 'rounded-md p-4',
  closeError: 'bg-red-100 text-red-500 hover:bg-red-200 focus:ring-offset-red-50 focus:ring-red-600',
  closeInfo: 'bg-blue-100 text-blue-500 hover:bg-blue-200 focus:ring-offset-blue-50 focus:ring-blue-600',
  closeSuccess: 'bg-green-100 text-green-500 hover:bg-green-200 focus:ring-offset-green-50 focus:ring-green-600',
  closeWarning: 'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:ring-offset-yellow-50 focus:ring-yellow-600',
}

export function NotificationElement({ children, className, layout, onClose, ...props }: NotificationElementProps) {
  return (
    <div
      className={clsx(
        classes.base,
        {
          'bg-blue-100': layout === 'info',
          'bg-green-100': layout === 'success',
          'bg-red-100': layout === 'error',
          'bg-yellow-100': layout === 'warning',
        },
        className,
      )}
      // biome-ignore lint/a11y/useSemanticElements: which one?
      role="alert"
      {...props}
    >
      <div className="flex">
        <div className="shrink-0">
          {layout === 'error' && <XCircleIcon className="h-5 w-5 text-red-500" />}
          {layout === 'info' && <InformationCircleIcon className="h-5 w-5 text-blue-500" />}
          {layout === 'warning' && <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500" />}
          {layout === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
        </div>
        <div className="ml-3">
          <p
            className={clsx('whitespace-pre-wrap text-sm font-medium', {
              'text-green-900': layout === 'success',
              'text-red-900': layout === 'error',
              'text-yellow-900': layout === 'warning',
            })}
          >
            {children}
          </p>
        </div>
        {onClose !== undefined && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                className={clsx('inline-flex rounded-md p-1.5 focus:outline-hidden focus:ring-2 focus:ring-offset-2', {
                  [classes.closeError]: layout === 'error',
                  [classes.closeInfo]: layout === 'info',
                  [classes.closeSuccess]: layout === 'success',
                  [classes.closeWarning]: layout === 'warning',
                })}
                onClick={onClose}
                type="button"
              >
                <span className="sr-only">{t('Dismiss')}</span>
                <XMarkIcon className="h5 w-5" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
