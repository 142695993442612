import type {
  AuthenticationError,
  BasicError,
  PublicationError,
  UniqueIsrcPublicationError,
  ValidationError,
} from 'generated/types'

import { isSchemaType } from 'utils/ts/type-guards'

export enum BackendPublicationErrors {
  AlbumTracksMissingStreams = 'album_tracks_missing_streams',
  ArtistRoleMissing = 'artist_role_missing',
  ComposersMissing = 'composers_missing',
  DateOfBirthMissing = 'date_of_birth_missing',
  DraftDeleteHasPublishedPerformanceWorks = 'performance_works_published_connected',
  DraftDeleteUsedInAlbums = 'albums_connected',
  DraftDeleteUsedInComposedWorks = 'composed_works_connected',
  DraftDeleteUsedInConcerts = 'concerts_connected',
  DraftDeleteUsedInConcertWorks = 'concert_works_connected',
  DraftDeleteUsedInPictures = 'pictures_connected',
  DraftDeleteUsedInTracks = 'tracks_connected',
  DraftDeleteUsedInWork = 'works_connected',
  DraftExistsUnpublishNotPossible = 'draft_exists_unpublish_not_possible',
  EpochMissing = 'epoch_missing',
  GenreMissing = 'genre_missing',
  EpochOnComposerMissing = 'epoch_on_composer_missing',
  IsrcNotUnique = 'isrc_not_unique',
  IsrcUsedInConcert = 'isrc_used_in_concert',
  IsrcUsedInPerformanceWork = 'isrc_used_in_performance_work',
  IsrcUsedInVideo = 'isrc_used_in_video',
  PerformanceWorkMissing = 'performance_works_missing',
  PerformanceWorksStreamsMissing = 'performance_works_missing_stream',
  PerformanceWorkUnpublished = 'performance_work_relation_unpublished',
  SettingStreamPermissionsFailed = 'setting_stream_permissions_failed',
  SliderEmpty = 'slider_empty',
  StreamingEndTimeBeforeStart = 'streaming_end_time_before_start',
  StreamingInvalidChannel = 'streaming_invalid_channel',
  StreamingInvalidIds = 'streaming_invalid_ids',
  StreamingInvalidJobState = 'streaming_invalid_job_state',
  StreamVariantsMissing = 'stream_variants_missing',
  StreamVariantsUnavailable = 'stream_variants_unavailable',
  TrailerStreamVariantsMissing = 'trailer_stream_variants_missing',
  TrailerStreamVariantsUnavailable = 'trailer_stream_variants_unavailable',
  UnpublishBannerUsedInContentContainer = 'banner_used_in_content_container',
  UnpublishSliderUsedInContentContainer = 'curated_slider_used_in_content_container',
  UnpublishUsedInAlbums = 'albums_published_connected',
  UnpublishUsedInArrangedWorks = 'arranged_works_published_connected',
  UnpublishUsedInArtists = 'artists_published_connected',
  UnpublishUsedInComposedWorks = 'composed_works_published_connected',
  UnpublishUsedInComposers = 'composers_published_connected',
  UnpublishUsedInConcerts = 'concerts_published_connected',
  UnpublishUsedInConcertWorks = 'concert_works_published_connected',
  UnpublishUsedInCuratedSliderItem = 'curated_slider_item_published_connected',
  UnpublishUsedInGroups = 'groups_published_connected',
  UnpublishUsedInLibrettoedWorks = 'librettoed_works_published_connected',
  UnpublishUsedInMovements = 'movements_published_connected',
  UnpublishUsedInPartners = 'partners_published_connected',
  UnpublishUsedInPictures = 'pictures_published_connected',
  UnpublishUsedInStageItem = 'stage_item_published_connected',
  UnpublishUsedInTracks = 'tracks_published_connected',
  UnpublishUsedInVideo = 'video_published_connected',
  UnpublishUsedInWorks = 'works_published_connected',
  // when trying to publish a live-concert after a connected vod-concert has been published
  VodConcertExists = 'vod_concert_exists',
  WorksMissing = 'works_missing',
}

export enum BackendValidationErrors {
  BannerRequired = 'picture_type_required_banner',
  CoverRequired = 'picture_type_required_cover',
  DefaultTranslationMissing = 'default_translation_present',
  FieldRequired = 'required',
  InputLength = 'length',
  MaximumFieldLength = 'maximum_string_length',
  NoStreamTrailerIsrc = 'no_stream_for_trailer_isrc',
  PartnerMustHaveLogo = 'picture_type_required_logo',
  PasswordNoConsecutive = 'password_no_consecutive',
  PasswordNoLowercase = 'password_no_lowercase',
  PasswordNonAscii = 'password_non_ascii_char',
  PasswordNoNumber = 'password_no_number',
  PasswordNoPalindrome = 'password_no_palindrome',
  PasswordNoSymbol = 'password_no_symbol',
  PasswordNoUppercase = 'password_no_uppercase',
  ProhibitedMarkdownElementUsed = 'prohibited_markdown_element_used',
  Subset = 'subset',
  TeaserRequired = 'picture_type_required_teaser',
  TeaserSquareRequired = 'picture_type_required_teaser_square',
  VodPerformanceWorkStreamRequired = 'vod_performance_work_stream_required',
}

export enum BackendBasicErrors {
  // when trying to create a draft for live concert that already has a vod
  LiveConcertDraftVodExists = 'create_draft_vod_concert_exists',
  LiveConcertHasNotEnded = 'live_concert_has_not_ended',
  LiveConcertIsDraft = 'live_concert_is_draft',
  // when attempting to copy a live concert that has a draft
  LiveConcertUnpublishedChanges = 'live_concert_has_unpublished_changes',
  NoDraftToDelete = 'no_draft_to_delete',
  NotFound = 'not_found',
  OperationUnsupported = 'operation_unsupported',
  // when attempting to copy a live concert to vod that already has a vod
  VodConcertAlreadyExists = 'vod_concert_already_present',
}

export const isAuthenticationError = isSchemaType<AuthenticationError>('AuthenticationError')
export const isValidationError = isSchemaType<ValidationError>('ValidationError')
export const isBasicError = isSchemaType<BasicError>('BasicError')
export const isPublicationError = isSchemaType<PublicationError>('PublicationError')
export const isUniqueIsrcPublicationError = isSchemaType<UniqueIsrcPublicationError>('UniqueIsrcPublicationError')
