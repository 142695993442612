import { clsx } from 'clsx'
import Link from 'next/link'

import { buttonTokens, forms } from 'components/elements/_tokens'
import { toKebabCase } from 'utils/formatters/change-case'
import type { SVGIcon } from 'utils/ts/shared-types'

type CardElementProps = {
  description: string
  disabled?: boolean
  href: string
  icon: SVGIcon
  title: string

  // should the description be truncated on overflow to ensure the same height for all items?
  truncate?: boolean
}

export function CardElement({ description, disabled, href, icon, title, truncate = true, ...props }: CardElementProps) {
  const Icon = icon
  return (
    <div
      className={clsx(
        buttonTokens.focus,
        'relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-xs hover:border-gray-400',
        { [forms.readonly]: disabled },
      )}
      data-testid={`card-${toKebabCase(title)}`}
      {...props}
    >
      <div className="shrink-0">
        <Icon className="h-10 w-10 text-gray-700" />
      </div>
      <div className="min-w-0 flex-1">
        <Link
          className={clsx('focus:outline-hidden', { 'cursor-not-allowed': disabled })}
          href={href}
          title={truncate ? undefined : description}
        >
          <span aria-hidden="true" className="absolute inset-0" />
          <p className="text-sm font-medium text-gray-900">{title}</p>
          <p className={clsx('text-sm text-gray-500', { truncate })}>{description}</p>
        </Link>
      </div>
    </div>
  )
}
