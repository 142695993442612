import type { RouteConfig } from 'utils/ts/shared-types'

import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import { roleEnum } from 'hooks/admin-user-types'
import { ASSET_DATA_ROUTES } from '../config'

export const ALBUM_LABEL = 'Album'

export const ASSET_DATA_ALBUM_ROUTES: RouteConfig<'edit'> = {
  edit: {
    label: 'Album Info',
    permissions: roleEnum.editor,
    uri: (albumId: string) => `/asset-data/albums/${encodeURIComponent(albumId)}`,
  },
  index: {
    label: 'Albums',
    permissions: roleEnum.editor,
    uri: () => '/asset-data/albums',
  },
}

export const ALBUM_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: ASSET_DATA_ROUTES.index.uri(), title: ASSET_DATA_ROUTES.index.label },
  { href: ASSET_DATA_ALBUM_ROUTES.index.uri(), title: ASSET_DATA_ALBUM_ROUTES.index.label },
]
