import type { ComponentPropsWithoutRef } from 'react'

type LoginPromptContainerProps = {
  headline: string
} & ComponentPropsWithoutRef<'div'>

export function LoginPromptContainer({ children, headline, ...props }: LoginPromptContainerProps) {
  return (
    <div className="bg-white shadow-sm sm:rounded-lg" {...props}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{headline}</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">{children}</div>
      </div>
    </div>
  )
}
