/** This files contains sets of tailwind classes that we want to be consistent across multiple components of a similar type
 * only add the classes here that you do expect every element to have
 */
export const forms = {
  addon:
    'inline-flex px-3 rounded-l-md border-r border-gray-300 bg-gray-50 text-gray-800 sm:text-sm font-mono tracking-wide',
  base: 'sm:text-sm border-gray-300 text-black font-normal',
  baseRadius: 'rounded-md',
  error: 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 hover:border-red-500',
  label: 'text-gray-700 sm:text-sm font-medium block',
  readonly: 'opacity-50 cursor-not-allowed focus:ring-0 focus:outline-hidden',
  untouched: 'focus:border-yellow-200 focus:ring-yellow-200 hover:border-yellow-200',
  valid: 'border-green-300 focus:border-green-400 focus:ring-green-400 hover:border-green-400',
}

export const buttonTokens = {
  base: [
    'inline-flex',
    'items-center',
    'text-base',
    'font-semibold',
    'tracking-tight',
    'shadow-xs',
    'transition-colors',
    'duration-150',
  ],
  disabled: 'opacity-50 cursor-not-allowed',
  focus: [
    'focus-within:outline-hidden',
    'focus-within:ring-2',
    'focus-within:ring-offset-2',
    'focus-within:ring-blue-400',
  ],
  icon: 'pl-3',
  primary: ['text-white', 'bg-blue-600', 'active:bg-blue-600', 'hover:bg-blue-700', 'border', 'border-transparent'],
  secondary: ['text-gray-700', 'bg-white', 'active:bg-white', 'hover:bg-gray-50', 'border', 'border-gray-300'],
}

// * used in selected-references, eg. when artist is referenced in a concert
export const referenceTokens = {
  base: 'flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-300 bg-white p-4 shadow-xs',
  hover: 'hover:border-gray-400',
}

export const buttonDropdownItemTokens = {
  base: 'text-gray-700 w-full font-medium',
  disabled: 'opacity-50',
  hasIcon: 'group flex items-center px-4 py-2 text-sm',
  hover: 'hover:bg-gray-100 hover:text-gray-900',
  icon: 'mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500',
  noIcon: 'block px-4 py-2 text-left text-sm',
}

export const convertForHelper = (classes: Record<string, string | string[]>): Record<string, string> =>
  Object.fromEntries(
    Object.entries(classes).map(([key, classes]) => [key, Array.isArray(classes) ? classes.join(' ') : classes]),
  )
